<template>
  <flex-row v-if="value" gap="4px">
    <flag :code="value.country_code" />
    <span>{{ value.country }}</span>
  </flex-row>
  <empty v-else />
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
