<template>
  <div class="chars">
    <div class="lightBackground">
      <div class="chars__title">{{ title }}</div>
    </div>
    <div class="chars__body">
      <div class="chars__list">
        <div v-for="char in chars" :key="char.title" class="chars__item">
          <div :title="char.title" class="chars__item-title darkGrey--text">
            {{ char.title }}
          </div>
          <div class="chars__item-value darkGrey--text">
            <component :is="getCharComponent(char.type)" :value="char.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OSChar from "@/view/components/Common/Characteristics/types/OSChar.vue";
import CountryChar from "@/view/components/Common/Characteristics/types/CountryChar.vue";
import TextChar from "@/view/components/Common/Characteristics/types/TextChar.vue";
import DateChar from "@/view/components/Common/Characteristics/types/DateChar.vue";
import IconChar from "@/view/components/Common/Characteristics/types/IconChar.vue";
import Listchar from "@/view/components/Common/Characteristics/types/ListChar.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    chars: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getCharComponent(type) {
      if (type === "os") {
        return OSChar;
      }

      if (type === "country") {
        return CountryChar;
      }

      if (type === "date") {
        return DateChar;
      }
      if (type === "icon") {
        return IconChar;
      }
      if (type === "list") {
        return Listchar;
      }

      return TextChar;
    },
  },
};
</script>

<style lang="scss" scoped>
.chars {
  display: grid;
  gap: 2px;

  &__head {
    background: #fafafa;
    border-radius: 2px;
  }

  &__body {
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 8px 12px;
  }

  &__list {
  }

  &__item {
    align-items: center;
    display: flex;

    &-title {
      color: rgba(0, 0, 0, 0.38);
      flex-basis: 140px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;

      padding: 8px 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-value {
      flex: 1;
      font-size: 14px;
    }
  }
}
</style>
