var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('flex-row', {
    attrs: {
      "gap": "4px"
    }
  }, [_c(_vm.value === 'android' ? 'android-logo' : 'apple-logo', {
    tag: "component"
  }), _c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(_vm._s(_vm.value))])], 1) : _c('empty');

}
var staticRenderFns = []

export { render, staticRenderFns }