var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('flex-row', {
    attrs: {
      "gap": "4px"
    }
  }, [_c('flag', {
    attrs: {
      "code": _vm.value.country_code
    }
  }), _c('span', [_vm._v(_vm._s(_vm.value.country))])], 1) : _c('empty');

}
var staticRenderFns = []

export { render, staticRenderFns }