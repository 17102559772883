var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('date-tooltip', {
    attrs: {
      "date": typeof _vm.value === 'string' ? _vm.getUnixTime(_vm.value) : _vm.value * 1000
    }
  }) : _c('empty');

}
var staticRenderFns = []

export { render, staticRenderFns }