<template>
  <flex-row v-if="value" gap="4px">
    <component :is="value === 'android' ? 'android-logo' : 'apple-logo'" />
    <span class="darkGrey--text">{{ value }}</span>
  </flex-row>
  <empty v-else />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
