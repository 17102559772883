var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-icon', {
    domProps: {
      "textContent": _vm._s(_vm.value)
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }