<template>
  <date-tooltip
    v-if="value"
    :date="typeof value === 'string' ? getUnixTime(value) : value * 1000"
  />
  <empty v-else />
</template>

<script>
import { getUnixTime } from "@/core/services/helper.service";

export default {
  methods: { getUnixTime },
  props: {
    value: {
      type: [String, Number, null],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>