var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chars"
  }, [_c('div', {
    staticClass: "lightBackground"
  }, [_c('div', {
    staticClass: "chars__title"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "chars__body"
  }, [_c('div', {
    staticClass: "chars__list"
  }, _vm._l(_vm.chars, function (char) {
    return _c('div', {
      key: char.title,
      staticClass: "chars__item"
    }, [_c('div', {
      staticClass: "chars__item-title darkGrey--text",
      attrs: {
        "title": char.title
      }
    }, [_vm._v(" " + _vm._s(char.title) + " ")]), _c('div', {
      staticClass: "chars__item-value darkGrey--text"
    }, [_c(_vm.getCharComponent(char.type), {
      tag: "component",
      attrs: {
        "value": char.value
      }
    })], 1)]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }