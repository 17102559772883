var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "py-4"
  }, _vm._l(Object.keys(_vm.value), function (item, index) {
    return _c('li', {
      key: index
    }, [_c('span', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(item))]), _vm._v(": "), _c('b', [_vm._v(" " + _vm._s(_vm.value[item]) + " ")])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }