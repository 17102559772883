<template>
  <div v-if="value">{{ value }}</div>
  <div v-else>-</div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
