<template>
  <ul class="py-4">
    <li v-for="(item, index) in Object.keys(value)" :key="index">
      <span class="text-capitalize">{{ item }}</span
      >:
      <b>
        {{ value[item] }}
      </b>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
